import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const backendTokenKey = 'backendToken'


export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function getTokenByMinute() {
  return Cookies.get(backendTokenKey)
}
export function setTokenByMinute(token, minute) {
    let expires = new Date(new Date() * 1 + minute * 60 * 1000);
    return Cookies.set(backendTokenKey, token, { expires: expires }); // n 秒后失效
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
